.actionButton {
  @apply shadow-card p-4 rounded-lg flex flex-col gap-3 justify-center items-center;
}

@media (min-width: 1600px) {
  .actionButton {
    @apply min-w-[245px] min-h-[284px] max-w-[245px] max-h-[284px];
  }
}

@media (min-width: 1370px) and (max-width: 1600px) {
  .actionButton {
    @apply min-w-[215px] min-h-[234px] max-w-[215px] max-h-[234px];
  }
}

@media (max-width: 1369px) {
  .actionButton {
    @apply flex-row w-full justify-start;
  }
  .actionButton.reversed {
    @apply justify-center;
  }
}

.actionButton.reversed {
  @apply bg-new-orange;
}
