.docList {
  @apply min-[1000px]:max-h-[700px] min-[1000px]:overflow-y-scroll
  min-[1000px]:min-w-[600px] min-[1300px]:min-w-[700px] min-[1500px]:min-w-[820px];
}

.docList::-webkit-scrollbar {
  @apply w-2 h-5;
}

.docList::-webkit-scrollbar-track {
  @apply bg-[#DDE3DD66] rounded-full;
}

.docList::-webkit-scrollbar-thumb {
  @apply bg-new-orange rounded-full;
}

.docList::-webkit-scrollbar-thumb:hover {
  @apply bg-opacity-50;
}

.docList .item {
  @apply max-w-[50rem] mb-4 border border-primary-container min-[1000px]:mr-3 rounded-lg;
}

.item .docData {
  @apply bg-neutral-surface border-b border-primary-container rounded-t-lg max-[999px]:p-2 p-5 flex items-center justify-between;
}

.docData .docTitle {
  @apply text-primary-main font-bold min-[1000px]:text-[1.25rem] min-[1000px]:leading-[1.25rem];
}

.docData .docInfo {
  @apply text-medium-emphasis text-lg max-[999px]:text-sm;
}
