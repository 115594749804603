.formBgContainer {
  @apply bg-white rounded p-10 shadow-card mt-24 mx-auto;
}

.formTitle {
  @apply text-primary-main text-customBig;
}

@media (max-width: 600px) {
  .formTitle {
    @apply text-lg text-center font-bold max-w-[277px] mx-auto;
  }
}

@media (max-width: 800px) {
  .formBgContainer {
    @apply mx-5;
  }
}

@media (min-width: 800px) {
  .formBgContainer {
    @apply max-w-[772px];
  }
}

@media (min-width: 1500px) {
  .formContainer {
    @apply flex mt-10;
  }
  .formBgContainer {
    @apply mb-32 relative -left-14;
  }
}
