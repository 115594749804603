.yourPlanContainer {
  @apply flex items-center gap-5;
}

.yourPlanContainer .yourPlanImage {
  @apply w-[656px] h-[517px] object-cover;
  overflow-clip-margin: unset;
}

@media (min-width: 901px) {
  .yourPlanContainer {
    background: linear-gradient(35.09deg, #60acc3 49.71%, #79bccf 71.47%);
  }
}

@media (max-width: 900px) {
  .yourPlanContainer {
    @apply flex-col-reverse;
    background: linear-gradient(254.66deg, #82bfd1 23.86%, #9bcedf 73.12%);
  }
  .yourPlanContainer .yourPlanImage {
    @apply w-full h-[455px] object-cover;
    overflow-clip-margin: unset;
  }
}

.healthContainer .healthTitle {
  @apply text-customBigger text-white mb-10 max-[1360px]:text-2xl max-[900px]:mt-10 max-w-[652px] font-bold;
}

@media (min-width: 1300px) {
  .healthContainer {
    @apply bg-[url('/public/assets/images/home/guy.jpeg')] bg-cover h-[827px] bg-[14%];
  }
  .healthContainer .healthTitle {
    @apply text-[3.875rem] leading-[5.813rem];
  }
  .healthContainer .textDiv {
    @apply p-32;
  }
}

@media (max-width: 1299px) {
  .healthContainer .healthTitle {
    @apply text-[2.875rem] leading-[4.313rem] mx-auto max-w-[403px];
  }
  .healthContainer {
    @apply bg-[#F0B2BC] pt-10;
  }
  .healthContainer .textDiv {
    @apply text-center;
  }
}
