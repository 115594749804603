.container {
  @apply bg-new-green flex items-center max-h-[539px] max-w-[1587px] mx-auto px-10;
}

.solImg {
  @apply w-[432px] h-[670.27px] -mt-[5.2rem] -ml-7 z-[20];
}

.mapImg {
  @apply h-[671.11px] w-[665px] -mb-10 z-10;
}

.fullText {
  @apply min-[1548px]:text-[2.625rem] min-[1548px]:leading-[3.75rem] min-[701px]:text-[2rem] text-lg text-primary-dark font-semibold max-[1621px]:mt-20 text-center max-w-[497px];
}

@media (min-width: 1200px) {
  .container {
    @apply rounded-lg;
  }
}

@media (min-width: 1600px) {
  .container {
    @apply max-w-[1587px];
  }

  .mapImg {
    @apply -ml-16;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .container {
    @apply max-w-[1150px] mx-auto px-5;
  }

  .solImg {
    @apply w-[342px] h-[490px] object-cover relative;
  }

  .mapImg {
    @apply -ml-16 w-[365px] object-contain relative -bottom-[2.5rem] max-h-[368px] left-5;
    /* 368px H */
  }

  .fullText {
    @apply text-[2rem];
    /* max-w-[400px]; */
  }
}

@media (max-width: 1199px) {
  .container {
    @apply flex-col;
  }
  .fullText {
    /* background-color: red; */
  }
  .solImg {
    @apply hidden;
  }
  .mapImg {
    @apply w-[339px] h-[342px];
  }
}
